let previousOrientation = window.orientation;

const virtualTour = () => {
  const $header = $('.c-header');
  const $footer = $('.c-footer');
  const $appointmentButton = $('.c-button');
  const $virtualTourCopy = $('.c-virtual-tour__copy');
  const $virtualTour = $('.c-virtual-tour');
  const landscape =
    Math.abs(window.orientation) === 90 && window.innerWidth < 1000;

  if (window.orientation !== previousOrientation) {
    previousOrientation = window.orientation;
    if (landscape) {
      $header.hide();
      $footer.hide();
      $appointmentButton.hide();
      $virtualTourCopy.hide();
      $virtualTour.css('marginTop', '0');
      $virtualTour.css('padding', '0');
    } else {
      $header.show();
      $footer.show();
      $appointmentButton.show();
      $virtualTourCopy.show();
      $virtualTour.css('marginTop', '0');
      $virtualTour.css('padding', '1em 2em');
    }
  }
};

window.addEventListener('resize', virtualTour, false);
window.addEventListener('orientationchange', virtualTour, false);

export default virtualTour;
