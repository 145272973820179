const expand = () => {
  const $btn = $('.js-expand');
  const isVisible = 'is-visible';
  const rotate = 'rotate';
  const footer = $('.c-footer');
  const body = $('body');
  const mask = 'mask';
  const isFixed = 'is-fixed';

  // show/hide footer bottom links
  $btn.on('click', (e) => {
    const target = $(e.target);
    target.next().toggleClass(isVisible);
    target.children().toggleClass(rotate);
    body.toggleClass(isFixed);
    footer.toggleClass(mask);
  });
};

export default expand;
