require('intersection-observer');

/* eslint-disable no-unused-vars */
const lazyBgImages = () => {
  const lazyBackgrounds = [].slice.call(document.querySelectorAll('.js-lazy-bg'));

  const options = {
    root: null, // avoiding 'root' or setting it to 'null' sets it to default value: viewport
    rootMargin: '0px',
    threshold: 0.25,
  };

  if ('IntersectionObserver' in window) {
    const lazyBackgroundObserver = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const lazyImage = entry.target;
          const imageURL = lazyImage.dataset.src;
          const isDataSrc = lazyImage.hasAttribute('data-src');
          if (isDataSrc) {
            lazyImage.style.backgroundImage = `url(${imageURL})`;
          }
          entry.target.classList.add('is-visible');
          lazyBackgroundObserver.unobserve(entry.target);
        }
      });
    }, options);

    lazyBackgrounds.forEach((lazyBackground) => {
      lazyBackgroundObserver.observe(lazyBackground);
    });
  }
};

export default lazyBgImages;
