/**
 * Returns an html template populated with data from an api
 * @param {object} data template data from api
 * @param {string} assetsPath url base of assets from api
 */

export const cardTemplate = (data, assetsPath) => {
  const isEvents = $('#events').length > 0;
  const basePath = isEvents ? '/event' : '/journal';
  const baseUrl = `${window.location.origin}${basePath}`;
  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  // https://stackoverflow.com/a/35107272/9084718
  const parseString = (s) => {
    const b = s.split(/\D/);
    return new Date(b[0], b[1] - 1, b[2], b[3], b[4], b[5]);
  };

  const date = isEvents ? new Date(parseString(data.startAt.date)) : null;
  const startDate = isEvents ? date : null;
  const dateMilliseconds = isEvents ? date.getTime() : null;
  const isEventClosed = Date.now() - 86400000 > dateMilliseconds && isEvents;
  const year = startDate.getFullYear().toString().substring(2, 4);

  const dataDate = `${startDate.getFullYear().toString()}-${startDate
    .getMonth()
    .toString()}`;

  const html = `
  <li class="c-events__item ${isEventClosed ? 'past-event' : ''}">
      <a href="${baseUrl}/${data.slug}" class="c-event-card__link">
      ${
        isEvents
          ? `<div class="c-event-card__date">
        <div class="c-event-card__day">${startDate.getDate()}</div>
        <span class="c-event-card__month" data-date="${dataDate}">${
              monthNames[startDate.getMonth()]
            }</span>
        <span class="c-event-card__year">&nbsp\`${year}</span>
      </div>`
          : ''
      }
      <div class="js-lazy-bg c-event-card__image is-visible ${
        isEventClosed ? 'is-closed' : ''
      }"
           style="background-image: url('${
             isEvents
               ? `${assetsPath}${data.mobileImage}`
               : `${assetsPath}${data.portraitImage}`
           }')"></div>
      <div class="c-event-card__content">
        <h3 class="c-event-card__heading">${data.name}</h3>
        <p class="c-event-card__copy">${data.shortDescription}</p>
      </div>
    </a>
  </li>
  `;
  return html;
};

/**
 * Checks if the footer is in the viewport and executes a callback if it is
 * @param {function} callback callback function
 */
export const isFooterVisible = (callback) => {
  // check if footer is visible to load more content
  if ('IntersectionObserver' in window) {
    const footer = document.querySelector('.c-footer');
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          callback();
        }
      });
    });

    observer.observe(footer);
  }
};
