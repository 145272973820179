const scrollTo = () => {
  const $htmlBody = $('html, body');
  const isDesktop = $(window).width() > 1024;

  // eslint-disable-next-line func-names
  $('a[href*="#"]').on('click', function (e) {
    e.preventDefault();
    const targetUrl = e.target.href;
    const currentUrlWithoutHash = window.location.href.split('#')[0];
    const targetUrlWithoutHash = targetUrl.split('#')[0];

    if (currentUrlWithoutHash === targetUrlWithoutHash) {
      const target = this.hash;
      const $target = $(target);
      const $scrollTo = $target.offset().top;

      // anchor scrolling at even speed
      const distance = Math.abs($(window).scrollTop() - $scrollTo);
      // const speed2 = distance / 2; slower speed
      const speed3 = distance / 3; // faster speed

      if (isDesktop) {
        $htmlBody.animate({
          scrollTop: $(target).offset().top,
        }, speed3, 'linear');
      } else {
        $htmlBody.animate({
          scrollTop: $(target).offset().top - 140,
        }, speed3, 'linear');
      }
    } else {
      window.location.href = targetUrl;
    }
  });
};

export default scrollTo;
