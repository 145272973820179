const mobileNav = () => {
  const $body = $('body');
  const $hamburger = $('.js-hamburger');
  const $menu = $('.js-nav-list');

  const bodyFixed = 'is-fixed';
  const hamburgerOpenClass = 'c-hamburger--open';
  const menuOpenClass = 'c-nav__list--open';

  $hamburger.on('click', () => {
    $body.toggleClass(bodyFixed);
    $hamburger.toggleClass(hamburgerOpenClass);
    $menu.toggleClass(menuOpenClass);
  });
};

export default mobileNav;
