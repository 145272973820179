const datePicker = () => {
  const $dateInput = $('.js-date-input');

  // add aria label to date and time input
  setTimeout(() => {
    const input = $('input.js-date-input.c-form__input.c-form__input--50.form-control.input');
    input.attr('aria-label', 'Date and Time*');
    input.on('focus').attr('readonly', true);
    input.attr('readonly', false);
  }, 500);

  /* https://flatpickr.js.org/options/
   * not sure why but ariaDateFormat
   * is not working
   */
  if ($dateInput.length) {
    $dateInput.flatpickr({
      enableTime: true,
      dateFormat: 'Y-m-d H:i',
      altInput: true,
      altFormat: 'H:i - F j, Y',
      minuteIncrement: 15,
    });
  }
};

export default datePicker;
