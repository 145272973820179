const formSubmitMessage = () => {
  const $form = $('form');
  const $message = $('.c-form__thank-you');
  const visibleClass = 'c-form__thank-you--visible';
  const $errorArea = $('.c-form__error');
  const $btn = $('#book_a_visit_attachment');

  $form.on('submit', function (e) {
    e.preventDefault();
    const action = $(this).attr('action');
    const formId = $(this).attr('id');
    const fd = new FormData($(this)[0]);

    $errorArea.empty();

    if (fd) {
      const recaptchaResponse = fd.get('g-recaptcha-response');
      if (recaptchaResponse && recaptchaResponse.length === 0) {
        $errorArea.append(
          '<p style="color: red; padding: .75rem">Please complete the reCAPTCHA</p>'
        );

        // remove all error messages after 3 seconds
        setTimeout(() => {
          $errorArea.empty();
        }, 3000);
      } else {
        $.ajax({
          type: 'post',
          url: action,
          data: fd,
          processData: false,
          contentType: false,
          success() {
            $(`#${formId}`).find($message).addClass(visibleClass);
            $(`#${formId}`)[0].reset();
            $btn.next('label').text('ATTACH A FILE');

            setTimeout(() => {
              $(`#${formId}`).find($message).removeClass(visibleClass);
            }, 10000);
          },
          error(err) {
            if (err.status === 400) {
              $.each(err.responseJSON.errors, (index, value) => {
                $errorArea.append(
                  `<p style="color: red; padding: .75rem">${value}</p>`
                );
              });
            }
          },
        });
      }
    }
  });
};

export default formSubmitMessage;
