import objectFitImages from 'object-fit-images';

import datePicker from './ui/date-picker';
import mobileNav from './ui/mobile-nav';
import scrollTo from './ui/scroll-to';
import slider from './ui/slider';
// import appointment from './ui/appointment';
import expand from './ui/expand';
import lazyBgImages from './ui/lazy-bg-images';
import lazyInlineImages from './ui/lazy-inline-images';
import googleMap from './ui/google-map';
import filtersInfiteScroll from './ui/filters-infinite-scroll';
import formSubmitMessage from './ui/form-submit-message';
import attachFile from './ui/attach-file';
import virtualTour from './ui/virtual-tour';
// import modal from './ui/modal';

// Object fit polyfill for IE (https://github.com/fregante/object-fit-images)
$(() => {
  objectFitImages();
});

const init = () => {
  datePicker();
  mobileNav();
  scrollTo();
  slider();
  // appointment();
  expand();
  lazyBgImages();
  lazyInlineImages();
  filtersInfiteScroll();
  formSubmitMessage();
  attachFile();
  virtualTour();
  // modal();
};

$(document).ready(init);

/* don't move it inside document ready
 * will throw an error InitMap is not a function
 */
googleMap();
