const slider = () => {
  const $slider = $('.js-slider');
  const $eventSlider = $('.js-slider-events');
  const $blogSlider = $('.js-slider-blog');
  const $heroSlider = $('.js-slider-homepage');
  const $eventListingSlider = $('.js-slider-event-listing');
  const $blogRelated = $('.js-slider-blog-related-article');
  const $eventListingSliderItmes = $('.js-slider-event-listing .c-slider__item').length;
  const isLessThan4 = $eventListingSliderItmes < 4;
  const eventListingClass = 'c-slider__list--event-listing';

  if (isLessThan4) {
    $eventListingSlider.addClass(eventListingClass);
  }

  if ($slider.length) {
    const prevArrow = '<button type="button" class="[ c-slider__button c-slider__button--prev ]" aria-label="Previous slide"><span class="[ c-slider__arrow c-slider__arrow--prev ]" aria-hidden="true">Previous slide</span></button>';
    const nextArrow = '<button type="button" class="[ c-slider__button c-slider__button--next ]" aria-label="Next slide"><span class="[ c-slider__arrow c-slider__arrow--next ]" aria-hidden="true">Previous slide</span></button>';

    const eventSettings = {
      infinite: false,
      mobileFirst: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      centerMode: true,
      centerPadding: '3rem',
      prevArrow,
      nextArrow,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1,
            centerMode: false,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            centerMode: false,
          },
        },
      ],
    };

    const blogSettings = {
      ...eventSettings,
      responsive: [
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            centerMode: false,
          },
        },
      ],
    };

    const heroSettings = {
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: true,
      cssEase: 'linear',
      arrows: true,
      dots: true,
      prevArrow,
      nextArrow,
    };

    const eventListingSettings = {
      ...eventSettings,
      arrows: false,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 1,
            centerMode: false,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            centerMode: false,
          },
        },
      ],
    };

    const blogRelatedSettings = {
      ...eventSettings,
      arrows: false,
      responsive: [
        {
          breakpoint: 700,
          settings: 'unslick',
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            centerMode: false,
          },
        },
      ],
    };

    $eventSlider.slick(eventSettings);
    $blogSlider.slick(blogSettings);
    $heroSlider.slick(heroSettings);
    $eventListingSlider.slick(eventListingSettings);
    $blogRelated.slick(blogRelatedSettings);
  }
};

export default slider;
