import moment from 'moment';
import { isFooterVisible, cardTemplate } from '../helpers/helpers';

/**
 * Filters for events and blogs pages
 */
const filtersInfiniteScroll = () => {
  const $select = $('.js-select');
  const $results = $('.js-results');
  const $dropdown = $('.js-dropdown');
  const $option = $('.js-option');
  const $spinner = $('.c-loading-spinner');
  const $message = $('.c-events__message');
  const pastEvents = $('#past-events');

  // const $noCategoryOption = $('[data-value=""][data-type="category"]');
  // const $noDateOption = $('[data-value=""][data-type="date"]');

  const showMessageClass = 'c-events__message--visible';
  const isOpenClass = 'is-open';
  const hiddenClass = 'c-loading-spinner--hidden';
  const selectedClass = 'c-dropdown__option--selected';
  const isEventOrBlog = $('#events').length > 0 || $('#blogs').length > 0;

  const isEventsPage = $('#events').length > 0;
  const noResultsHtml =
    '<div class="c-events__message c-events__message--visible">&mdash; No results found &mdash;</div>';

  const baseUrl = `/api/${isEventsPage ? 'events' : 'blog'}`;

  /**
   * Application state, used as the source of truth for the filtering and infinite scroll
   */
  const state = {
    // url parameters
    params: {
      date: '',
      category: '',
      page: 0,
    },
    // true if no more results were found
    loadEnd: false,
    // true if an api call is in progress
    loading: false,
  };

  /**
   * returns the current endpoint URL based on app state
   */
  const getCurrentUrl = () => {
    const urlParams = Object.entries(state.params)
      // remove parameters with falsy values
      .filter((entry) => entry[1])
      // convert each param to a url string
      .map((param) => `${param[0]}=${param[1]}`)
      .join('&');
    return `${baseUrl}?${urlParams}`;
  };

  /**
   * Add the initial results to the DOM (i.e. Page 0 of results)
   * @param {string} assetsPath base url of assets for api data
   * @param {array} results array of results received from the api
   */
  const renderInitialResults = (assetsPath, results = []) => {
    if (results.length) {
      const openEventsResultsHtml = results
        .filter(
          (item) =>
            moment(item.startAt.date).format() >
            moment.utc().subtract(1, 'day').format()
        )
        .map((r) => cardTemplate(r, assetsPath))
        .join('');

      const pastEventResultsHtml = results
        .filter(
          (item) =>
            moment(item.startAt.date).format() <
            moment.utc().subtract(1, 'day').format()
        )
        .map((r) => cardTemplate(r, assetsPath))
        .join('');

      $results.html(openEventsResultsHtml);
      pastEvents.html(pastEventResultsHtml);

    } else {
      $results.html(noResultsHtml);
    }
  };

  /**
   * Append subsequently loaded results to the DOM (i.e. inifinite scroll)
   * @param {string} assetsPath base url of assets for api data
   * @param {array} results array of results received from the api
   */
  const renderLoadedResults = (assetsPath, results = []) => {
    if (results.length) {
      const resultsHtml = results
        .map((r) => cardTemplate(r, assetsPath))
        .join('');
      pastEvents.append(resultsHtml);
    } else {
      $spinner.addClass(hiddenClass);
      $message.addClass(showMessageClass);

      setTimeout(() => {
        $message.removeClass(showMessageClass);
      }, 3000);
    }
  };

  /**
   * Make ajax call for initial results (Results will replace the entire results containers)
   */
  const getInitialResults = () => {
    state.loading = true;
    // reset the page number
    state.params.page = 0;

    $.ajax({
      url: getCurrentUrl(),
      headers: { 'X-Requested-With': 'XMLHttpRequest' },
      dataType: 'json',
      success(response) {
        if (response.data.length) {
          state.loading = false;
          state.loadEnd = false;
          renderInitialResults(response.assets_path, response.data);
        } else {
          state.loading = false;
          state.loadEnd = true;
          renderInitialResults();
        }
      },
    });
  };

  /**
   * Make ajax call for loaded results (results will be appended to the initial results)
   */
  const getLoadedResults = () => {
    state.params.page += 1;
    state.loading = true;

    // show loading spinner
    $spinner.removeClass(hiddenClass);

    $.ajax({
      url: getCurrentUrl(),
      dataType: 'json',
      success(response) {
        if (response.data.length) {
          state.loading = false;
          $spinner.addClass(hiddenClass);
          renderLoadedResults(response.assets_path, response.data);
        } else {
          state.loading = false;
          state.loadEnd = true;
          renderLoadedResults();
        }
      },
    });
  };

  // open/close custom select
  $dropdown.on('click', function () {
    $(this).children().toggleClass(isOpenClass);
  });

  // /**
  //  * Updates the selected option in the DOM for the custom dropdown
  //  * @param {JQuery} $element jquery object of the element being selected
  //  */
  // const selectOption = ($element) => {
  //   const selectedValue = $element.text();
  //   $element.parent().find(`.${selectedClass}`).removeClass(selectedClass);
  //   $element.addClass(selectedClass);
  //   $element.closest('.js-select').find('.js-select-value').text(selectedValue);
  // };

  // click a dropdown option
  $option.on('click', function () {
    const dataType = $(this).data('type');
    const dataDate = $(this).data('date');
    const dataValue = $(this).data('value');
    // selectOption($(this));

    const selectedValue = $(this).text();
    console.log('selectedValue', selectedValue);
    $(this).parent().find(`.${selectedClass}`).removeClass(selectedClass);
    $(this).addClass(selectedClass);
    $(this).closest('.js-select').find('.js-select-value').text(selectedValue);

    // The user can only select one of date or category at a time
    if (dataType === 'date') {
      // clear the category selection
      // selectOption($noCategoryOption);
      // state.params.category = '';
      // set the date in state
      state.params.date = dataDate;
    } else {
      // // clear date selection
      // selectOption($noDateOption);
      // state.params.date = '';
      // set the category in state
      state.params.category = dataValue;
    }

    // make ajax request for initial results
    getInitialResults();
  });

  // close custom select
  $(window).on('click', (e) => {
    $select.toArray().forEach((select) => {
      if (!select.contains(e.target)) {
        $(select).removeClass(isOpenClass);
      }
    });
  });

  /**
   * load more if the ajax request is complete and the end of results hasn't been reached
   */
  const infiniteScroll = () => {
    if (!state.loading && !state.loadEnd) {
      getLoadedResults();
    }
  };

  if (isEventOrBlog) {
    isFooterVisible(infiniteScroll);
    getInitialResults();
  }
};

export default filtersInfiniteScroll;
