require('intersection-observer');

/**
 * Lazy load inline images
 * Example markup: <img class="js-lazy other-class is-hidden" data-src="/path/to/image">
 */
const lazyInlineImages = () => {
  const lazyImages = [].slice.call(document.querySelectorAll('.js-lazy'));

  if ('IntersectionObserver' in window) {
    const lazyImageObserver = new IntersectionObserver(((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const lazyImage = entry.target;
          lazyImage.src = lazyImage.dataset.src;
          lazyImage.classList.remove('is-hidden');
          lazyImageObserver.unobserve(lazyImage);
        }
      });
    }));

    lazyImages.forEach((lazyImage) => {
      lazyImageObserver.observe(lazyImage);
    });
  }
};

export default lazyInlineImages;
