const attachFile = () => {
  const $btn = $('#book_a_visit_attachment');
  const $errorArea = $('#c-error-message');

  $btn.change(function () {
    $errorArea.empty();
    $(this).next('label').clone();

    const file = $btn[0].files[0];
    console.log(file.size);
    // 10485760 aprox 10MB
    if (file.size > 10485760) {
      $(this).next('label').text('ATTACH A FILE');
      $errorArea.append(
        '<p style="color: red; padding: .75rem">Invalid file, please upload a .pdf, .doc, .docx, .ppt, .jpg or .png file with max of 10MB.</p>'
      );
    } else {
      $(this).next('label').text(file.name);
    }
  });
};

export default attachFile;
